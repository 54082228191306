import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import { mapActions, mapGetters } from 'vuex'

export default mixins(Section).extend({
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      regulations: 'setup/getRegulations',
      privacyPolicy: 'setup/getPrivacyPolicy'
    })
  },
  methods: {
    ...mapActions({
      fetchRegulations: 'setup/fetchRegulations',
      fetchPrivacyPolicy: 'setup/fetchPrivacyPolicy'
    })
  }
})
